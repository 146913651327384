<template>
  <svg :class="svgClass">
    <use :xlink:href="iconPath" />
  </svg>
</template>

<script>
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'SvgIcon',
  props: {
    iconClass: {
      required: true,
    },
    className: {
      default: '',
    },
  },
  setup(props) {
    const svgClass = computed(() => (props.className ? `svg-icon ${props.className}` : 'svg-icon'))
    const iconPath = computed(() => `#icon-${props.iconClass}`)
    return {
      svgClass,
      iconPath,
    }
  },
})
</script>

<style lang="scss" scoped>
/* 添加全局或内联样式 */
svg {
  width: 1em;
  height: 1em;
  // 继承父色
  fill: currentColor;
}
</style>
