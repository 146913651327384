import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import store from './store';
import Antd from "ant-design-vue";
// import api from '@/request/api/index';
import { message } from "ant-design-vue";
import SvgIcon from "@/components/SvgIcon/index.vue";

import "ant-design-vue/dist/reset.css"; // 引入样式文件
const app = createApp(App);

// 提供给所有Vue组件使用
app.provide("message", message);
// app.config.globalProperties.$apis = api;
// app.config.globalProperties.$store = store;

// 全局注册SvgIcon组件
app.component("svg-icon", SvgIcon);
const req = require.context("./icons/svg", false, /\.svg$/);
const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);
requireAll(req);

app.use(Antd);
// app.use(store);
app.use(router);
app.mount("#app");
