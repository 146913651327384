import { createRouter, createWebHistory } from "vue-router";

const routesLayout = [
  {
    path: "/",
    name: "",
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: "/play",
    name: "play",
    component: () => import("@/views/play/index.vue"),
  },
];
const routes = [...routesLayout];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
